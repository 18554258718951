import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { breakpoints } from '../../../styles/screenSizes';

gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.section`
    margin-top: 110px;
    padding: 82px 69px;
    background-color: #050521;
    height: 100%;
    min-height: 70vh;

    @media only screen and (max-width: ${breakpoints.md}) {
        margin-top: 72px;
        padding: 36px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        margin-top: 54px;
        padding: 36px 16px;
    }
`;

const Content = styled.div`
    height: 100%;
    max-width: 1280px;
    margin: 0 auto;
    overflow: hidden;
`;

const HeadingContainer = styled.div`
    text-align: center;
    padding: 0 82px;

    @media only screen and (max-width: ${breakpoints.md}) {
        padding: 0 14px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        padding: 0px;
    }
`;

const Heading = styled.h1`
    color: #fff;
    font-size: 48px;
    line-height: 67.2px;
    font-weight: 500;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 28px;
        line-height: 33.6px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        font-size: 24px;
    }
`;

const SubText = styled.p`
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    padding-top: 24px;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 14px;
        line-height: 20px;
        padding-top: 18px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        font-size: 12px;
        line-height: 18px;
        padding-top: 12px;
    }
`;

const Box = styled.div`
    width: 100%;
    margin-top: 54px;
    display: flex;
    flex-wrap: nowrap;
    gap: 84px;

    @media only screen and (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: 24px;
    }
`;

const DataCard = styled.div`
    display: flex;
    min-width: 100%;
    gap: 84px;

    @media only screen and (max-width: ${breakpoints.sm}) {
        flex-direction: column-reverse;
        gap: 16px;
        padding: 16px 12px;
        border-radius: 8px;
        background-color: #121212;
        border: 1px solid #1d2939;
    }
`;

const LeftBox = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;

    @media only screen and (max-width: ${breakpoints.md}) {
        gap: 10px;
    }
    @media only screen and (max-width: ${breakpoints.sm}) {
        gap: 0px;
        padding-top: 28px;
    }
`;

const Icon = styled.div`
    border: 1px solid #dddddd;
    border-radius: 4px;
    height: 44px;
    width: 44px;
    background-color: #fff;
    display: grid;
    place-items: center;

    @media only screen and (max-width: ${breakpoints.md}) {
        height: 32px;
        width: 32px;

        img {
            height: 20px;
            width: 20px;
        }
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        background-color: transparent;
        border: 1px solid #475467;

        img {
            height: 18px;
            width: 18px;
            filter: hue-rotate(215deg) sepia(10%) saturate(100%) invert(100%);
        }
    }
`;

const Title = styled.h2`
    font-size: 44px;
    line-height: 52.14px;
    font-weight: 500;
    color: #fff;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 16px;
        line-height: 20px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        padding: 12px 0 4px 0;
        font-size: 18px;
        line-height: 21.33px;
    }
`;

const Desc = styled.p`
    color: #fff;
    font-size: 22px;
    line-height: 30.8px;
    font-weight: 400;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 14px;
        line-height: 20px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        font-size: 14px;
        color: #b3b3b3;
        line-height: 19.6px;
    }
`;

const RightBox = styled.div`
    flex: 1;
    border-radius: 12px;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        border-radius: 6.58px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        border-radius: 8px;

        img {
            height: auto;
            width: 100%;
        }
    }
`;
const VerificationSteps = ({ data }) => {
    const cardsRef = React.useRef([]);
    const wrapperRef = React.useRef(null);

    useEffect(() => {
        if (window.innerWidth > parseInt(breakpoints.md)) {
            const cards = cardsRef.current;
            const totalScrollDistance = (cards[1].offsetWidth + 84) * (cards.length - 2);

            const timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: wrapperRef.current,
                    pin: true,
                    scrub: 0.1,
                    start: 'center center',
                    end: () => `+=${totalScrollDistance + 6000}`,
                },
            });

            timeline.to(cards, {
                x: 0,
                duration: 0.2,
                ease: 'none',
            });

            timeline.to(cards, {
                x: -totalScrollDistance,
                duration: 1,
                ease: 'none',
            });

            timeline.to({}, { duration: 0.2 });

            return () => {
                ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
                timeline.kill();
            };
        }
    }, [cardsRef, wrapperRef]);
    return (
        <Wrapper ref={wrapperRef}>
            <Content>
                <HeadingContainer>
                    <Heading>The Authenticate Way</Heading>
                    <SubText>
                        Get top-tier verification at unbeatable prices! With Authenticate you can
                        layer up your checks without overspending. It's affordable, secure, and
                        thorough—everything you need in one place!
                    </SubText>
                </HeadingContainer>
                <Box>
                    {data?.map((item) => (
                        <DataCard key={item.id} ref={(el) => (cardsRef.current[item.id] = el)}>
                            <LeftBox>
                                <Icon>
                                    <img src={item.icon} alt={item.title + ' icon'} />
                                </Icon>
                                <Title>{item.title}</Title>
                                <Desc>{item.desc}</Desc>
                            </LeftBox>
                            <RightBox>
                                <img src={item.img} alt={item.title} />
                            </RightBox>
                        </DataCard>
                    ))}
                </Box>
            </Content>
        </Wrapper>
    );
};

VerificationSteps.propTypes = {
    data: PropTypes.array,
};

export default VerificationSteps;
