import React from 'react';
import styled from 'styled-components';

const HyperLink = styled.a`
    font-weight: 500;
    text-decoration: underline;
    color: #fff;
`;
const IFPBannerSubText = () => {
    return (
        <>
            Effortlessly automate your security with our ready-to-use Identity Verification APIs and
            background check solutions. Test-drive our{' '}
            <HyperLink
                href="https://docs.authenticate.com/reference/mock-apis"
                target="_blank"
                rel="noreferrer"
            >
                Mock APIs
            </HyperLink>{' '}
            today and integrate seamlessly in no time!
        </>
    );
};

export default IFPBannerSubText;
